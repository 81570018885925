import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appHover]',
  standalone: true
})
export class HoverDirective implements AfterViewInit {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private animationDuration = 300;
  private leaving: number | null = null;

  ngAfterViewInit(): void {
    const ul = this.el.nativeElement.children[1];
    ul.classList.add(`duration-${this.animationDuration}`);
    ul.classList.add('hidden');
  }

  constructor() {
    if (isPlatformServer(inject(PLATFORM_ID))) {
      return;
    }
    if (window.matchMedia("(pointer: coarse)").matches) {
      // Disable on touch screen
      return;
    }

    this.renderer.listen(this.el.nativeElement, 'mouseover', () => {
      if (this.leaving) {
        clearTimeout(this.leaving);
        this.leaving = null;
      }
      const ul = this.el.nativeElement.children[1];
      ul.classList.remove('hidden');
      setTimeout(() => {
        ul.classList.remove('opacity-0');
        ul.classList.add('opacity-100');
      });
    });
    this.renderer.listen(this.el.nativeElement, 'mouseleave', () => {
      this.leaving = window.setTimeout(() => {
        const ul = this.el.nativeElement.children[1];
        setTimeout(() => {
          ul.classList.add('hidden');
        }, this.animationDuration);
        ul.classList.add('opacity-0');
        ul.classList.remove('opacity-100');
      }, 100);
    });
  }
}
